<template>
  <v-container fluid>
    <v-card tile flat>
      <skeleton-page-heading v-if="loading" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <action-bar
          :show="false"
          :refresh="refresh"
          :loading="loading"
          :search="search"
          :search-data="searchData"
        />
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <skeleton-table v-if="loading" />
        <server-side-table
          v-else
          :headers="headers"
          :footers="footers"
          :total-items="totalItems"
          :items="items"
          :loading="loading"
          :update-item-per-page="updateItemPerPage"
          :get-data-from-api="getDataFromApi"
          :remove-item="removeItem"
          :from="from ? from : 0"
          :total="total"
          :page="page"
          :route-detail="routeDetail"
          :action-show="true"
          :action-delete="true"
          :action-edit="false"
          :action-detail="true"
          :action-status="false"
          :action-approval="false"
          :action-time-counter="true"
          :action-created-at="true"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/mixin";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import ActionBar from "../components/ActionBar.vue";
import ServerSideTable from "../components/ServerSideTable.vue";
import SkeletonTable from "../components/SkeletonTable.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";

export default {
  components: {
    BreadcrumbComponent,
    ActionBar,
    ServerSideTable,
    SkeletonTable,
    SkeletonPageHeading,
  },
  mixins: [mixin],
  data: () => ({
    url: "trackings",
    routeDetail: 'tracking-detail',
    headers: [
      { text: "#", value: "order" },
      { text: "Name", value: "user.name" },
      { text: "Project Name", value: "project.name" },
      { text: "Property", value: "property_detail.name" },
      { text: "Time Counter", value: "time_counter" },
      { text: "Tanggal Dibuat", value: "created_at" },
      { text: "Action", value: "actions" },
    ],
  }),
};
</script>

<style></style>
